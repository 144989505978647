<template>
	<div>
		<div class="page-sub-box ewm">
			<div class="tree-grid">
				<div class="space-y-6 pt-8">
					<div>
						<DxButton
							text="조회"
							class="btn_M box-btn-search default filled"
							type="button"
							:height="30"
							@click="selectTargetDataList"
						/>
					</div>
					<div>
						<DxTreeList
							key-expr="codeId"
							parent-id-expr="parentId"
							:root-value="940"
							ref="workStatusGrid"
							:data-source="workStatusData.dataSource"
							:allow-column-resizing="true"
							:column-resizing-mode="'nextColumn'"
							:show-borders="false"
							:show-column-headers="true"
							:show-column-lines="true"
							:show-row-lines="true"
							:row-alternation-enabled="false"
							:hover-state-enabled="true"
							:word-wrap-enabled="true"
							:no-data-text="this.$_msgContents('CMN_NO_DATA')"
							:expanded-row-keys="workStatusData.expandedRowKeys"
							:auto-expand-all="true"
							width="100%"
						>
							<DxLoadPanel :enabled="true" />

							<DxColumn caption="부서정보" data-field="codeNm" :allowEditing="false" :allow-sorting="true" width="220" />
							<DxColumn caption="근속기간">
								<DxColumn
									caption="3개월 미만"
									data-field="threeMonth"
									alignment="center"
									:allowEditing="false"
									:allow-sorting="true"
								/>
								<DxColumn
									caption="6개월 미만"
									data-field="sixMonth"
									alignment="center"
									:allowEditing="false"
									:allow-sorting="true"
								/>
								<DxColumn
									caption="1년 미만"
									data-field="oneYear"
									alignment="center"
									:allowEditing="false"
									:allow-sorting="true"
								/>
								<DxColumn
									caption="2년 미만"
									data-field="twoYear"
									alignment="center"
									:allowEditing="false"
									:allow-sorting="true"
								/>
								<DxColumn
									caption="3년 미만"
									data-field="threeYear"
									alignment="center"
									:allowEditing="false"
									:allow-sorting="true"
								/>
								<DxColumn
									caption="5년 미만"
									data-field="fiveYear"
									alignment="center"
									:allowEditing="false"
									:allow-sorting="true"
								/>
								<DxColumn
									caption="5년 이상"
									data-field="fiveYearMore"
									alignment="center"
									:allowEditing="false"
									:allow-sorting="true"
								/>
								<DxColumn
									caption="합계"
									data-field="totalCount"
									alignment="center"
									:allowEditing="false"
									:allow-sorting="true"
								/>
							</DxColumn>
							<DxColumn
								caption="평균재직기간"
								data-field="avgPeriodString"
								alignment="center"
								:allowEditing="false"
								:allow-sorting="true"
							/>
						</DxTreeList>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { DxButton } from 'devextreme-vue/button';
import { DxColumn, DxLoadPanel } from 'devextreme-vue/data-grid';
import { DxTreeList } from 'devextreme-vue/tree-list';
import { isSuccess } from '@/plugins/common-lib';

let vm = null;

export default {
	components: {
		DxButton,
		DxColumn,
		DxLoadPanel,
		DxTreeList,
	},
	props: {},
	watch: {},
	data() {
		return {
			codeMap: {},
			codes: {
				dept1: {
					dataSource: [],
				},
				dept2: {
					dataSource: [],
				},
				dept3: {
					dataSource: [],
				},
			},
			deptData: {
				dataSource: null,
				selectedRowsData: [],
				selectedRowKeys: [],
				expandedRowKeys: [],
				isLoaded: true,
			},
			workStatusData: {
				dataSource: null,
				selectedRowsData: [],
				selectedRowKeys: [],
				expandedRowKeys: [],
				isLoaded: true,
			},
			targetGridData: {
				dataSource: [],
				selectedRowsData: [],
				selectedRowKeys: [],
				expandedRowKeys: [],
				isLoaded: true,
			},
		};
	},
	computed: {},
	methods: {
		/** @description : 조회 */
		async selectTargetDataList() {
			this.$refs['workStatusGrid'].instance.beginCustomLoading();

			const payload = {
				actionname: 'EWM_AGENT_SEARCH',
				loading: false,
			};
			const res = await vm.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				const filteredData = res.data.data.filter(item => item.userStateCd != 'RETIRED' && item.delFl == 'N');
				const currentDate = new Date();
				filteredData.forEach(d => {
					const joinDate = new Date(d.joinDt); //입사일자
					const differenceInTime = currentDate - joinDate;
					const differenceInDays = Math.floor(differenceInTime / (1000 * 60 * 60 * 24) + 1);
					d.totalPeriod = differenceInDays; //전체 일수
					const months = Math.floor(differenceInDays / 30);
					d.monthsPeriod = months; //개월
					d.center = parseInt(d.deptCdPath.slice(0, 3)); //센터
					d.department = parseInt(d.deptCdPath.slice(4, 7)); //파트
					d.team = parseInt(d.deptCdPath.slice(8, 11)); //팀
				});
				this.targetGridData.dataSource = filteredData;
			}

			this.deptData.dataSource.forEach(w => {
				w.threeMonth = 0;
				w.sixMonth = 0;
				w.oneYear = 0;
				w.twoYear = 0;
				w.threeYear = 0;
				w.fiveYear = 0;
				w.fiveYearMore = 0;
				w.totalCount = 0;
				w.avgPeriod = 0;

				this.targetGridData.dataSource.forEach(t => {
					if (t.center === w.codeId) {
						w.totalCount += 1;
						w.avgPeriod += t.totalPeriod;
						switch (true) {
							case t.monthsPeriod < 3:
								w.threeMonth += 1;
								break;
							case t.monthsPeriod < 6:
								w.sixMonth += 1;
								break;
							case t.monthsPeriod < 12:
								w.oneYear += 1;
								break;
							case t.monthsPeriod < 24:
								w.twoYear += 1;
								break;
							case t.monthsPeriod < 36:
								w.threeYear += 1;
								break;
							case t.monthsPeriod < 48:
								w.fiveYear += 1;
								break;
							case t.monthsPeriod > 47:
								w.fiveYearMore += 1;
								break;
							default:
								break;
						}
					} else if (t.department === w.codeId) {
						w.totalCount += 1;
						w.avgPeriod += t.totalPeriod;
						switch (true) {
							case t.monthsPeriod < 3:
								w.threeMonth += 1;
								break;
							case t.monthsPeriod < 6:
								w.sixMonth += 1;
								break;
							case t.monthsPeriod < 12:
								w.oneYear += 1;
								break;
							case t.monthsPeriod < 24:
								w.twoYear += 1;
								break;
							case t.monthsPeriod < 36:
								w.threeYear += 1;
								break;
							case t.monthsPeriod < 48:
								w.fiveYear += 1;
								break;
							case t.monthsPeriod > 47:
								w.fiveYearMore += 1;
								break;
							default:
								break;
						}
					} else if (t.team === w.codeId) {
						w.totalCount += 1;
						w.avgPeriod += t.totalPeriod;
						switch (true) {
							case t.monthsPeriod < 3:
								w.threeMonth += 1;
								break;
							case t.monthsPeriod < 6:
								w.sixMonth += 1;
								break;
							case t.monthsPeriod < 12:
								w.oneYear += 1;
								break;
							case t.monthsPeriod < 24:
								w.twoYear += 1;
								break;
							case t.monthsPeriod < 36:
								w.threeYear += 1;
								break;
							case t.monthsPeriod < 48:
								w.fiveYear += 1;
								break;
							case t.monthsPeriod > 47:
								w.fiveYearMore += 1;
								break;
							default:
								break;
						}
					}

					if (w.totalCount < 1) {
						w.avgPeriodString = `-`;
					} else {
						const totalMonths = Math.floor(w.avgPeriod / w.totalCount / 30);

						if (totalMonths < 11) {
							w.avgPeriodString = `${totalMonths}개월`;
						} else {
							const totalYears = Math.floor(totalMonths / 12);
							const remainingMonths = totalMonths % 12;
							w.avgPeriodString = `${totalYears}년${remainingMonths > 0 ? '개월' : ''}`;
						}
					}
				});
			});

			this.workStatusData.dataSource = this.deptData.dataSource;

			this.$refs['workStatusGrid'].instance.endCustomLoading();
		},
		/** @description : 코드 로드 */
		async initCodeMap() {
			this.codeMap = await this.$_getCodeMapList('root_ewm_hr_dept');
		},
		async createdData() {
			vm = this;

			await this.initCodeMap().then(() => {
				this.codes.dept1.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_dept'], 2);
				this.codes.dept2.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_dept'], 3);
				this.codes.dept3.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_dept'], 4);

				this.deptData.dataSource = [...this.codes.dept3.dataSource, ...this.codes.dept2.dataSource, ...this.codes.dept1.dataSource];
			});
		},
		/** @description : 라이프사이클 mounted시 호출되는 메서드 */
		mountData() {
			this.selectTargetDataList();
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountData();
	},
};
</script>
